<template>
	<div class="FicheClient pa-5">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight" :subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy" :offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity" :overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate" :titleColor="Head.TitleColor" />

		<InfoCustomer :customer="customer" :company="userData.company" v-if="userData.company" />

		<InfoCompany class="pt-3" v-bind:company="customer.companyInfo" v-if="hasCompany" fromCustomer />

		<v-row class="mt-3">

			<v-col cols="12">
				<v-row>
					<v-col cols="6" align="center">

						<h2 class="font-weight-regular">Statut</h2>

						<v-btn-toggle v-model="customerStatus" :color="customerStatus.color" group mandatory>
							<v-btn v-for="status in $listUtils.entityStatus" :key="status.label" :value="status" class="mx-0" @click="changeStatus(status)">
								{{ status.label }}
							</v-btn>
						</v-btn-toggle>

					</v-col>

					<v-col cols="6" align="center">

						<h2 class="font-weight-regular">Funnel</h2>

						<v-btn-toggle v-model="funnelCustomerStatus" :color="funnelCustomerStatus.color" group mandatory>
							<v-btn v-for="status in $listUtils.funnelEntityStatus" :key="status.label" :value="status" class="mx-0" @click="changeFunnelStatus(status)">
								{{ status.label }}
							</v-btn>
						</v-btn-toggle>

					</v-col>
				</v-row>
			</v-col>

			<v-col>
				<div class="mb-2">
					<v-btn v-if="!edit" block outlined color="primary" @click="edit = true">Modifier</v-btn>
					<v-btn v-else block outlined color="success" @click="submit()">Sauvegarder</v-btn>
				</div>

				<div>
					<v-btn v-if="!hasCompany" block outlined color="primary" @click="dialogs.addCompany = true">Ajouter à une entreprise</v-btn>
					<v-btn v-else block outlined color="primary" @click="removeCompany()">Retirer de l'entreprise</v-btn>
				</div>
			</v-col>

		</v-row>


		<Remarks
			v-if="infoLoaded"
			type="customers"
			:entity="customer"
			:user="userData"
		/>

		<Mails
			v-if="infoLoaded && customer.email"
			:entity="customer"
			:user="userData"
		/>

		<!--<subscriptions />
		<commands />
		<invoices />
		<shippings />-->
		
		<InfoComplementary :data="customer" v-bind:edit="edit" />

		<InfoBank :data="customer" v-if="infoLoaded" v-bind:edit="edit" />

		<v-row class="mt-3">
			
			<v-spacer></v-spacer>

			<v-col cols="2">
				<v-btn v-if="!edit" block text color="primary" @click="edit = true">Modifier</v-btn>
				<v-btn v-else block text color="success" @click="submit()">Sauvegarder</v-btn>
			</v-col>

		</v-row>

		<v-dialog v-model="dialogs.addCompany" max-width="600px">
			<v-card>
				<v-card-title class="headline">
					Lier {{ customer.lastName.toUpperCase() }} {{ customer.firstName }} à une entreprise.
				</v-card-title>
				<v-card-text>
					<v-autocomplete rounded clearable v-model="companySelect" :items="people" chips deletable-chips color="blue-grey lighten-2" label="Entreprise" item-text="id" item-value="id">
						<template v-slot:selection="data">
							<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
								<v-avatar left>
									<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
								</v-avatar>
								{{ data.item.name }}
							</v-chip>
						</template>
						<template v-slot:item="data">
							<v-list-item-avatar>
								<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<!-- v-html="data.item.name" -->
								<v-list-item-title>{{ data.item.name }}</v-list-item-title>
								<v-list-item-subtitle v-html="'Numéro ' + data.item.denom + ' : ' + data.item.content">
								</v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</v-autocomplete>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" text @click="stateCompany()">
						Ajouter
					</v-btn>

					<v-btn color="primary" text @click="dialogs.addCompany = false">
						Annuler
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	import Head from '@/components/graphics/head';

	import Remarks from '@/components/modules/@widgets/Remarks';
	import Mails from '@/components/modules/@widgets/Mails';

	/*import subscriptions from '@/components/modules/@widgets/subscriptions';
	import commands from '@/components/modules/@widgets/commands';
	import invoices from '@/components/modules/@widgets/invoices';
	import shippings from '@/components/modules/@widgets/shippings';*/

	import InfoComplementary from '@/components/modules/@widgets/InfoComplementary';
	import InfoBank from '@/components/modules/@widgets/InfoBank';

	import InfoCompany from '@/components/modules/@company/InfoCompany';

	import InfoCustomer from '@/components/modules/@customer/InfoCustomer';

	export default {
		props: {
			'id': {
				type: String
			}
		},
		components: {
			Head,

			InfoCustomer,
			Remarks,
			Mails,
			/*subscriptions,
			commands,
			invoices,
			shippings,*/
			InfoComplementary,
			InfoBank,
			InfoCompany
		},
		data() {
			return {
				customer: this.$models.user,
				company: this.$models.company,

				userData: this.$models.user,

				edit: false,

				Head: {
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "10vh",
					height: "45vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"
				},

				dialogs: {
					addCompany: false
				},

				companySelect: '',

				people: [],

				customerStatus: {
					color: 'primary'
				},

				funnelCustomerStatus: {
					color: 'primary'
				}
			}
		},
		computed: {
			hasCompany: function () {
				if(this.customer.company && this.customer.companyInfo) return true;
				else return false;
			},
			infoLoaded: function () {
				if(this.customer.id) return true;
				else return false;
			}
		},
		methods: {
			stateCompany() {
				this.$db.collection("companies").doc(this.userData.company).collection("customers").doc(this.id).set({
					'company': this.companySelect
				}, {
					merge: true
				}).then(() => {
					this.getCompany(this.userData.company);

					this.$store.dispatch('setSnackbar', {
						text: `L'entreprise a bien été liée à ${this.customer.civility} ${this.customer.lastName.toUpperCase()}`,
					});

					this.dialogs.addCompany = false;
				});
			},
			removeCompany() {
				this.$db.collection("companies").doc(this.userData.company).collection("customers").doc(this.id).set({
					'company': ""
				}, {
					merge: true
				}).then(() => {
					this.getCustomer(this.userData.company);

					this.companyInfo = {};

					this.$store.dispatch('setSnackbar', {
						text: `L'entreprise a bien été retirée à ${this.customer.civility} ${this.customer.lastName.toUpperCase()}`,
					});
				});
			},

			submit() {
				const newUsr = this.customer;
				delete newUsr.id;
				delete newUsr.creationDate;
				delete newUsr.company;

				this.$db.collection("companies").doc(this.userData.company).collection("customers").doc(this.id).set({
					...newUsr
				}, {
					merge: true
				}).then(() => {
					this.edit = false;

					this.$store.dispatch('setSnackbar', {
						text: `${newUsr.civility} ${newUsr.lastName.toUpperCase()} - Client n°${newUsr.nClient} a été modifié !`,
					});
				})
			},
			
			changeStatus: function(attr) {
				this.$db.collection("companies").doc(this.userData.company).collection("customers").doc(this.id).set({
					status: this.$listUtils.entityStatus.indexOf(attr)
				}, {
					merge: true
				}).then(() => {
					this.edit = false;
				});
			},
			
			changeFunnelStatus: function(attr) {
				this.$db.collection("companies").doc(this.userData.company).collection("customers").doc(this.id).set({
					funnel: this.$listUtils.funnelEntityStatus.indexOf(attr)
				}, {
					merge: true
				}).then(() => {
					this.edit = false;
				});
			},

			getCompany(companyId) {
				this.$db.collection("companies").doc(companyId).collection("companies").doc(this.customer.company).get().then((docComp) => {
						this.customer.companyInfo = {
							...docComp.data(),
							id: docComp.id
						};
					});

					this.$db.collection("companies").doc(this.userData.company).onSnapshot(doc => {
						this.company = doc.data();
					});
			},

			getCustomer(companyId) {
				this.$db.collection("companies").doc(companyId).collection("customers").doc(this.id).onSnapshot(doc => {
					this.customer = {
						...doc.data(),
						creationDate: this.$moment(),
						id: doc.id
					};

					if(this.customer.company) {
						this.getCompany(companyId);
					}

					this.customerStatus = this.$listUtils.entityStatus[this.customer.status];
					this.funnelCustomerStatus = this.customer.funnel ? this.$listUtils.funnelEntityStatus[this.customer.funnel] : this.$listUtils.funnelEntityStatus[0];

					this.Head.Title = document.title = `${this.customer.civility} ${this.customer.lastName.toUpperCase()} - Client n°${this.customer.nClient}`;
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(docu => {
				this.userData = {
					...docu.data(),
					id: docu.id
				};

				this.$db.collection("companies").doc(docu.data().company).collection("companies").get().then((res) => {
					res.forEach((document) => {
						this.people.push({
							'name': document.data().name,
							'content': document.data().nCompany,
							'id': document.id,
							'avatar': document.data().avatar,
							'denom': "entreprise"
						});
					});
				});

				this.getCustomer(docu.data().company);				
			});
		}
	}
</script>